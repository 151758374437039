<template>
     <div class="filters_sec" :class="{'open':openFilter}">
    
        <button :disabled="isselectedGlobalCLient" @click="toggleFilter()" class="filters_btn">Filters</button>
        <div class="filters_cnt">
            <div class="row">
               <template v-if="showFilter">
               <!-- <div class="filters_list col-md">
                    <h6>Status</h6>
                  
                    <multiselect
                    v-model="selected_status"
                        :value="'_id'"
                        :options="client_status"
                        :searchable="true"
                        :close-on-select="false"
                        :hide-selected="true"
                        :allow-empty="true"
                        :multiple="true"
                        label="name"
                        placeholder="Select status"
                        track-by="name"
                    >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                        <span
                        class="multiselect__selectcustom"
                        v-if="values.length && !isOpen"
                        >{{ values.length }} Status selected</span
                        >
                        <span
                        class="multiselect__selectcustom"
                        v-if="values.length && isOpen"
                        ></span>
                    </template>
                    </multiselect>
                    
                </div>-->

                <div class="filters_list col-md"  >
                    <h6>States</h6>
                    <multiselect
                    v-model="selectedUsstateList"
                        :value="'_id'"
                        :options="usstateList"
                        :searchable="true"
                        :close-on-select="false"
                        :hide-selected="true"
                        :allow-empty="true"
                        :multiple="true"
                      
                        label="name"
                        placeholder="Select state"
                        track-by="name"
                    >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                        <span
                        class="multiselect__selectcustom"
                        v-if="values.length && !isOpen"
                        >{{ values.length }} State's selected</span
                        >
                        <span
                        class="multiselect__selectcustom"
                        v-if="values.length && isOpen"
                        ></span>
                    </template>
                    </multiselect>
                </div>
                 </template>
                <div class="filters_list col-md"  >
                
                    <h6>Filed Date</h6> 

                    <date-range-picker placeholder="YYYY-MM-DD" :maxDate="new Date()" :autoApply="autoApply" :opens="'left'" :ranges="false"   v-model="selected_createdDateRange"></date-range-picker>
                </div>
                 
            </div>
            <div class="filter_actions">
                <button class="secondary_btn" @click="clearFilter()">Clear<i class="fas fa-redo"></i></button>
                <div class="d-flex">
                    <button class="filter_close secondary_btn" @click="openFilter=false;toggleBodyClass('removeClass', 'hidden')">Cancel</button>
                    <button class="filter_close primary_btn" @click="applyFilters()">Apply</button>
                </div>
            </div>
        </div>
        <span class="filters_layer"  @click="toggleFilter()"></span>
    </div>
</template>
<script>
import _ from "lodash"; 
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import moment from "moment";
import Multiselect from "vue-multiselect-inv";

export default {
    computed:{
        
        
        
    },
    components: {
        DateRangePicker,
        Multiselect
    },
    data() {
        return {
            isselectedGlobalCLient:false,
            bus_type: [],
            client_status: [],
            platform: [],
            nexus: [],
            llc_type: [],

            selected_bus_type: [],
            selected_status: [],
            selected_platform: [],
            selected_nexus: [],
            selected_llc_type: [],
            selected_createdDateRange:{},
            autoApply: "",
            usstateList:[],
            selectedUsstateList:[],
            selectedClientList:[]
            
        }
    },
    methods: {
        
        getUsStatesList(){
        let payLoad = {
		"matcher": {
			"searchString":"",
			//"cntryId": 101,
			//"stateId": 665,
			"cntryIds": [231], // Requied for "state" 
			//"stateIds": [665], // Requied for "city"
		},
		"page": 1,
		"perpage": 10000000000,
		"category": "state", // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
	} ;
         this.$store.dispatch("getMaterData",payLoad)
         .then((res)=>{
             this.usstateList = res.list;
             

         });
    },
        getMaterData(category){
            let payLoad = {
            "matcher": {
                "searchString":"",
            },
            "page": 1,
            "perpage": 10000000000,
            "category": category, // acc_method, bank, user_status, user_role, user_list, country, state, platform, business, bus_type, notes_category, notes_status, client_status, nexus, pay_frequency
            } ;
            this.$store.dispatch("getMaterData",payLoad)
                .then((res)=>{
                    this[category] = res.list;
                    if(category =="client_status"){
                       if(this.userdata.roleId ==2){
                            this[category] =_.filter(res.list ,(rol)=>{
                                return rol._id !=1
                                
                            });

                        }
                        

                    }
                })
                .catch((err)=>{
                    this.log(err);
                });
        },
        setPreference(event, item, selectedFltr, list) {
           
            if (item.selected) {
                if( this[selectedFltr].indexOf(item._d) <=-1){
                    this[selectedFltr].push(item._id);
                }
            } else {
                if( this[selectedFltr].indexOf(item._id) >=-1){
                    this[selectedFltr].splice(  this[selectedFltr].indexOf(item._id) ,1 );
                    _.forEach(this[list] ,(object ,index)=>{
                        if(item._id == object._id){
                            this[list][index].selected = false;
                        }
                    })
                }

            }
        },
        applyFilters() {
            this.toggleBodyClass('removeClass', 'hidden');
            let filterData = {'busTypeIds':[] , "clientIds":[],'statusIds':[], 'sellingPlatformIds':[] , 'nexusTypeIds':[], 'llcTypeIds':[] , 'createdOnRange': [] ,"stateIds":[]}
           //  filterData.statusIds = this.selected_status
            filterData.busTypeIds = this.selected_bus_type
            filterData.sellingPlatformIds = this.selected_platform
            filterData.nexusTypeIds = this.selected_nexus
             filterData.llcTypeIds = this.selected_llc_type
            if((_.has(this.selected_createdDateRange ,'startDate' ) && this.selected_createdDateRange['startDate']  && this.selected_createdDateRange['startDate'] !=null ) &&
             ( _.has(this.selected_createdDateRange ,'endDate' ) && this.selected_createdDateRange['endDate']  && this.selected_createdDateRange['endDate'] !=null ) 
             
             ){
                filterData['createdOnRange'].push(moment(this.selected_createdDateRange['startDate']).format("YYYY-MM-DD"));
                filterData['createdOnRange'].push(moment(this.selected_createdDateRange['endDate']).format("YYYY-MM-DD"));
            }


            if(this.selected_status.length>0){
                _.forEach(this.selected_status ,(state)=>{
                    filterData['statusIds'].push(state['_id']);
                });
            }
            if(this.selectedUsstateList.length>0){
                _.forEach(this.selectedUsstateList ,(state)=>{
                    filterData['stateIds'].push(state['_id']);
                });
            } 
            
            if(this.selectedClientList.length>0){
                _.forEach(this.selectedClientList ,(state)=>{
                    filterData['clientIds'].push(state['_id']);
                });
            } 

            this.$emit("applyFilter" ,filterData);
            this.openFilter=false;
            this.selectedClientList =[];
            
        },
        clearFilter() {
             this.toggleBodyClass('removeClass', 'hidden');
            this.selected_bus_type = [];
            this.selected_status = [];
            this.selected_platform = [];
            this.selected_nexus = [];
            this.selected_llc_type = [];
            this.selectedUsstateList =[];
            this.selected_createdDateRange = {startDate:null ,endDate:null};
            

            this.bus_type.forEach(obj => {
                obj.selected = false;
            })
            this.client_status.forEach(obj => {
                obj.selected = false;
            })
             this.platform.forEach(obj => {
                obj.selected = false;
            })
            this.nexus.forEach(obj => {
                obj.selected = false;
            })
             this.llc_type.forEach(obj => {
                obj.selected = false;
            })
            this.selectedClientList =[];

            if(this.selectedGlobalClient !=null  && ( _.has(this.selectedGlobalClient , "_id")  ) ){
                this.selectedClientList.push(this.selectedGlobalClient);
                 this.applyFilters();
            }else{
                 this.$emit("clearFilter");
            }
            this.openFilter=false;
             
        }
    },
    mounted(){

        this.getMaterData("bus_type");
        this.getMaterData("client_status");
        this.getMaterData("platform");
        this.getMaterData("nexus");
        this.getMaterData("llc_type");
        this.getUsStatesList();
      
    },
    props:{
        showFilter:{
            type:Boolean,
            default:true
        },
        showStateFilter:{
            type:Boolean,
            default:false
        },
        selectedGlobalClient:{"name":'',"_id":''},
    },
    watch:{
        
        selectedGlobalClient:function(val){
            this.selected_bus_type = [];
            this.selected_status = [];
            this.selected_platform = [];
            this.selected_nexus = [];
            this.selected_llc_type = [];
            this.selectedUsstateList =[];
            this.selected_createdDateRange = {};

            this.bus_type.forEach(obj => {
                obj.selected = false;
            })
            this.client_status.forEach(obj => {
                obj.selected = false;
            })
             this.platform.forEach(obj => {
                obj.selected = false;
            })
            this.nexus.forEach(obj => {
                obj.selected = false;
            })
             this.llc_type.forEach(obj => {
                obj.selected = false;
            })
            this.isselectedGlobalCLient = false;
            if(val !=null  && (_.has(val , "_id") && val['_id']  ) ){
                this.selectedClientList.push(val);
                this.isselectedGlobalCLient = true;

            }
            this.openFilter=false;
            this.applyFilters();
         

      }
    }
    
}
</script>