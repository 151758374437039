<template>
  <div class="body_section">
    <profileHeader
      :showFilter="this.getInvitations == false"
      @changedGlobalclient="changedGlobalclient"
      ref="profile_hrader"
    />
    <div class="body_content">
      <div class="main_content">
        <div class="subheader">
          <div>
            <input
              v-if="!isselectedGlobalCLient"
              class="search"
              type="text"
              v-model="serch_title"
              @keyup="serchTitle()"
              placeholder="Search by business name"
            />
          </div>
          <div class="d-flex">
            <tableheader
              @applyFilter="applyFilters"
              @clearFilter="clearFilters"
              :selectedGlobalClient="selectedGlobalClient"
              :showStateFilter="getInvitations"
            ></tableheader>
            <template v-if="isselectedGlobalCLient">
              <button
                v-if="loaded && userdata.roleId == 1"
                :disabled="isselectedGlobalCLient"
                class="primary_btn"
              >
                Invite Client
              </button>
            </template>
            <template v-else>
              <button
                v-if="loaded && userdata.roleId == 1"
                class="primary_btn"
                @click="showModal"
              >
                Invite Client
              </button>
            </template>
          </div>
        </div>
        <div class="status">
          <ul
            v-if="!isselectedGlobalCLient"
            :class="{
              current: getInvitations == false,
              current: getInvitations == true,
            }"
          >
            <li
              :class="{ active: getInvitations == false }"
              @click="
                getInvitations = false;
                clearFilters();
                showFilter = true;
              "
            >
              <span>Submitted</span>
            </li>
            <li
              :class="{ active: getInvitations == true }"
              @click="
                getInvitations = true;
                clearFilters();
                showFilter = false;
              "
            >
              <span>Invited</span>
            </li>
          </ul>
        </div>
        <div class="list_table client_table padt0">
          <table class="table table-hover">
            <thead>
              <tr>
                <th scope="col">
                  <span>
                    <a
                      @click="sortMe('busName')"
                      v-bind:class="{
                        sort_ascending: sortKeys['busName'] == 1,
                        sort_descending: sortKeys['busName'] != 1,
                      }"
                      >Business Name</a
                    ></span
                  >
                </th>
                <th v-if="userdata.roleId == 1">Filling Fee</th>
                <th scope="col">Registered States</th>
                <th scope="col">States Operated</th>
                <th scope="col">Next Filing Deadline</th>
                <!--<th scope="col">
                      <span>
                            <a @click="sortMe('statusName')"
                                v-bind:class="{
                                sort_ascending: sortKeys['statusName'] == 1,
                                sort_descending: sortKeys['statusName'] != 1,
                                }"
                                >Status</a
                            ></span></th>-->
                <th scope="col" class="w-80">Actions</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="list && list.length > 0">
                <tr v-for="(item, index) in list" :key="index">
                  <td
                    @click="setGloalClient(item)"
                    :class="{ pointer: userdata['roleId'] != 5 }"
                  >
                    <figure @click="gotoDetails(item)">
                      <span><img @error="setAltImg" :src="item.logo"/></span>
                      <!--<span></span>-->
                      <figcaption>{{ item.busName }}</figcaption>
                    </figure>
                    <!-- {{item}}-->
                  </td>
                  <td v-if="userdata.roleId == 1">
                    {{
                      checkProperty(item, "settings", "filingCharges") != "--"
                        ? "$" + item["settings"]["filingCharges"]
                        : ""
                    }}
                  </td>
                  <td>
                    <div
                      v-if="
                        getInvitations == false &&
                          checkProperty(item, 'stateReg') != '--' &&
                          item.stateReg.length > 0
                      "
                      class="td_chips"
                    >
                      <template v-for="(item1, ind) in item.stateReg">
                        <div :key="ind" v-if="ind < 4">
                          <span :id="item._id + item1._id">{{
                            item1.sName
                          }}</span>
                          <b-popover
                            :target="item._id + item1._id"
                            triggers="hover"
                            placement="top"
                          >
                            {{ checkProperty(item1, "name") }}
                          </b-popover>
                        </div>
                      </template>

                      <div class="td_hidden_chips">
                        <template v-if="item.stateReg.length > 4">
                          <span id="popover-target-1"
                            >+{{ item.stateReg.length - 4 }}
                          </span>
                        </template>
                        <b-popover
                          v-if="item.stateReg.length > 4"
                          target="popover-target-1"
                          triggers="hover"
                          placement="bottom"
                        >
                          <div class="td_hidden_chips_list">
                            <ul>
                              <template v-for="(item1, ind) in item.stateReg">
                                <li v-if="ind >= 4" :key="ind">
                                  {{ item1.sName }}
                                </li>
                              </template>
                            </ul>
                          </div>
                        </b-popover>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div
                      v-if="
                        getInvitations == false &&
                          checkProperty(item, 'operatingStates') != '--' &&
                          item.stateReg.length > 0
                      "
                      class="td_chips"
                    >
                      <template v-for="(item1, ind) in item.operatingStates">
                        <div :key="ind" v-if="ind < 4">
                          <span :id="item._id + '_' + item1._id">{{
                            item1.sName
                          }}</span>
                          <b-popover
                            :target="item._id + '_' + item1._id"
                            triggers="hover"
                            placement="top"
                          >
                            {{ checkProperty(item1, "name") }}
                          </b-popover>
                        </div>
                      </template>

                      <div class="td_hidden_chips">
                        <template v-if="item.operatingStates.length > 4">
                          <span id="popover-target-1"
                            >+{{ item.operatingStates.length - 4 }}
                          </span>
                        </template>
                        <b-popover
                          v-if="item.operatingStates.length > 4"
                          target="popover-target-1"
                          triggers="hover"
                          placement="bottom"
                        >
                          <div class="td_hidden_chips_list">
                            <ul>
                              <template
                                v-for="(item1, ind) in item.operatingStates"
                              >
                                <li v-if="ind >= 4" :key="ind">
                                  {{ item1.sName }}
                                </li>
                              </template>
                            </ul>
                          </div>
                        </b-popover>
                      </div>
                    </div>
                  </td>
                  <td>{{ item.nextPaymentOn | formatDate }}</td>
                  <!--<td>

                               <span v-if="getInvitations == false" class="td_status" :class="{'pending':item.statusId==1 , 'active':item.statusId==2 , 'inactive':item.statusId== 3 ,'deleted':item.statusId==4}"
                                >{{item['statusDetails']['name']}}
                                </span>
                            </td>-->
                  <td>
                    <b-dropdown right dropdown>
                      <template #button-content>
                        <b-icon
                          icon="three-dots-vertical"
                          aria-hidden="true"
                        ></b-icon>
                      </template>
                      <b-dropdown-item href="#"
                        ><router-link :to="'/questionnaire/' + item._id"
                          >Edit</router-link
                        ></b-dropdown-item
                      >
                      <b-dropdown-item href="#" v-if="getInvitations == false"
                        ><router-link :to="'/client-details/' + item._id"
                          >DETAILS</router-link
                        ></b-dropdown-item
                      >
                      <b-dropdown-item
                        href="#"
                        v-if="userdata.roleId == 1 && getInvitations == false"
                        ><router-link :to="'/state-details/' + item._id"
                          >CREDENTIALS</router-link
                        ></b-dropdown-item
                      >

                      <b-dropdown-item
                        v-if="userdata.roleId == 1 && getInvitations == false"
                        @click="showModal2(item)"
                        >FILING FEE
                      </b-dropdown-item>
                      <!-- <b-dropdown-item href="#" @click="onPopup3(item)"  v-if="userdata.roleId==1 & (item.statusId==2 || item.statusId==3)">
                                             {{ item.statusId==2?'Deactivate':item.statusId==3?'Deactivate':'Activate'}}
                                   </b-dropdown-item>-->
                    </b-dropdown>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <template v-if="showLoading">
            <span class="no_data list_loader"
              >Loading<img src="@/assets/images/loader.gif"
            /></span>
          </template>

          <template v-if="list.length <= 0 && loaded && !showLoading">
            <span class="no_data">No Clients Found!</span>
          </template>

          <!-- <b-pagination v-if="list.length > 0"
            v-model="page"
            :total-rows="totalPages"
            :per-page="perPage"
             pills

            ></b-pagination> -->
        </div>
        <div class="pagination_wrap">
          <!-- <div v-if="list.length > 0">
                <div class="pagination_content">
                    <h6>Show Records </h6>
                    <multiselect
                    :multiple="false"
                    @input="changePerPage()"
                    placeholder="Page"
                    :close-on-select="true"
                    :clear-on-select="false"
                    :hide-selected="true"
                    :preserve-search="true"
                    v-model="perPage"
                    :options="perPeges"
                    >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                        <span
                        class="multiselect__selectcustom"
                        v-if="values.length && !isOpen"
                        >{{ values.length }} selected</span
                        >
                        <span
                        class="multiselect__selectcustom"
                        v-if="values.length && isOpen"
                        ></span>
                    </template>
                    </multiselect>
                </div>
            </div> -->

          <div v-show="list.length > 0">
            <div class="pagination_content">
              <h6>Show Records</h6>
              <multiselect
                :multiple="false"
                @input="changePerPage()"
                placeholder="Page"
                :close-on-select="true"
                :clear-on-select="false"
                :hide-selected="true"
                :preserve-search="true"
                v-model="perPage"
                :options="perPeges"
              >
                <template slot="selection" slot-scope="{ values, isOpen }">
                  <span
                    class="multiselect__selectcustom"
                    v-if="values.length && !isOpen"
                    >{{ values.length }} selected</span
                  >
                  <span
                    class="multiselect__selectcustom"
                    v-if="values.length && isOpen"
                  ></span>
                </template>
              </multiselect>
            </div>
          </div>

          <b-pagination
            v-if="list.length > 0"
            v-model="page"
            :total-rows="totalPages"
            :per-page="perPage"
            pills
            first-number
            last-number
          ></b-pagination>
        </div>
        <b-modal ref="my-modal" centered hide-footer hide-header>
          <div class="create_popup">
            <div class="modal-body">
              <div class="modal_left">
                <div class="modal_title">
                  <h3>Invite Client</h3>
                </div>
                <figure><img src="@/assets/images/client_vector.svg" /></figure>
                <span class="userlabel"
                  ><img src="@/assets/images/user_group.svg"
                /></span>
              </div>
              <div class="modal_right">
                <b-button
                  class="close"
                  variant="outline-danger"
                  block
                  @click="hideModal"
                  ><span aria-hidden="true">&times;</span></b-button
                >

                <!-- <form> -->
                <ValidationObserver
                  ref="sendInvitationForm"
                  v-slot="{ invalid, validated, handleSubmit, validate }"
                >
                  <div class="form-group">
                    <ValidationProvider
                      name="Business"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <input
                        type="text"
                        v-model="new_client.busName"
                        class="form-control business"
                        aria-describedby="emailHelp"
                        placeholder="Business Name"
                      />
                      <span class="v-messages error-msg">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <h6>Primary Contact Person</h6>
                  <div class="form-group name-group ">
                    <ValidationProvider
                      name="First Name"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <input
                        type="text"
                        v-model="new_client.busContact.fName"
                        class="form-control firstname"
                        id="FirstName"
                        aria-describedby="emailHelp"
                        placeholder="First Name"
                      />
                      <span class="v-messages error-msg">{{ errors[0] }}</span>
                    </ValidationProvider>
                    <ValidationProvider
                      name="Last Name"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <input
                        type="text"
                        v-model="new_client.busContact.lName"
                        class="form-control lastname"
                        id="LastName"
                        aria-describedby="emailHelp"
                        placeholder="Last Name"
                      />
                      <span class="v-messages error-msg">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group phone-group">
                    <ValidationProvider
                      name="Phone Number"
                      rules="required|phonenumber"
                      v-slot="{ errors }"
                    >
                      <VuePhoneNumberInput
                        default-country-code="US"
                        :no-example="true"
                        :only-countries="['US']"
                        :no-country-selector="true"
                        @update="updatePhoneNumber"
                        :required="true"
                        v-model="new_client.busContact.phone"
                      />
                      <span class="v-messages error-msg">{{ errors[0] }}</span>
                    </ValidationProvider>
                  </div>
                  <div class="form-group">
                    <div class="form-group">
                      <ValidationProvider
                        name="Email"
                        rules="required|email"
                        v-slot="{ errors }"
                      >
                        <input
                          type="email"
                          solo
                          v-model="new_client.busContact.email"
                          class="form-control email"
                          label="Email"
                          placeholder="Email"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="form-group">
                      <input
                        type="website"
                        solo
                        v-model="new_client.busContact.website"
                        class="form-control website"
                        label="Website"
                        placeholder="Website"
                      />
                    </div>
                  </div>

                  <div class="form-group">
                    <div class="form-group">
                      <h6>Pricing Modal</h6>
                      <ValidationProvider
                        name="Pricing Modal"
                        rules="required"
                        v-slot="{ errors }"
                      >
                        <!-- <textarea  rows="4" class="form-textarea form-control" v-model="new_client.pricingModel"  label="Price Modal" placeholder="Price Modal"  ></textarea>-->
                        <ckeditor
                          placeholder="Price Modal"
                          v-validate="'required|min:50'"
                          :editor="editor"
                          v-model="new_client.pricingModel"
                          :config="editorConfig"
                        ></ckeditor>
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>

                  <span
                    class="primary_btn"
                    @click="
                      erororVisible = false;
                      validate();
                      handleSubmit(sendInvitation);
                    "
                    :disabled="invalid || !validated || formSubmited"
                    >Send Invite</span
                  >
                </ValidationObserver>
                <!-- </form> -->
              </div>
            </div>
          </div>
        </b-modal>

        <!-- <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Close Me</b-button>
        <b-button class="mt-2" variant="outline-warning" block @click="toggleModal">Toggle Me</b-button> -->
        <b-modal
          ref="my-modal2"
          centered
          hide-footer
          hide-header
          class="modal_popup"
          id="setprice_popup"
        >
          <div class="modal_popup profile_popup">
            <b-button class="close" block @click="hideModal2"
              ><span aria-hidden="true">&times;</span></b-button
            >
            <div class="profile_head padtb20">
              <!--<div class="profile_title">
                        <figure>
                            <img  @error="setAltImg" :src="checkProperty(selectedItem, 'logo')"  >
                        </figure>
                    </div>-->

              <div class="setprice_details">
                <h5>{{ checkProperty(selectedItem, "busName") }}</h5>
                <!--<p>{{checkProperty(selectedItem, 'logo')}}</p>-->
              </div>
            </div>
            <ValidationObserver ref="priceForm" v-slot="{ validate }">
              <template>
                <div class="row">
                  <div class="col">
                    <div class="form-group">
                      <label class="form_label">Filling Fee</label>
                      <ValidationProvider
                        name="Price"
                        rules="required|maxval:0"
                        min="0"
                        v-slot="{ errors }"
                      >
                        <input
                          type="number"
                          class="form-control"
                          min="0"
                          v-model="set_price.filingCharges"
                        />
                        <span class="v-messages error-msg">{{
                          errors[0]
                        }}</span>
                      </ValidationProvider>
                    </div>
                  </div>
                </div>
              </template>

              <div class="d-flex justify-content-end">
                <button
                  class="primary_btn"
                  @click="
                    erororVisible = false;
                    validate();
                    setFilingCharges();
                  "
                  :disabled="
                    set_price.filingCharges < 0 ||
                      set_price.filingCharges == null ||
                      set_price.filingCharges == ''
                  "
                >
                  Save
                </button>
              </div>
            </ValidationObserver>
          </div>
        </b-modal>

        <b-modal
          ref="my-modal3"
          centered
          hide-footer
          hide-header
          class="modal_popup"
        >
          <div class="modal_popup profile_popup alert_popup">
            <b-button
              class="close"
              block
              @click="
                selectedItem = null;
                hideMe();
              "
              ><span aria-hidden="true">&times;</span></b-button
            >
            <div class="profile_head">
              <label>Do you want to {{ actionTitle }} this user.. ?</label>
            </div>
            <div class="d-flex justify-content-center">
              <button
                class="secondary_btn"
                @click="
                  selectedItem = null;
                  hideMe();
                "
              >
                No
              </button>
              <button
                class="primary_btn marl15"
                @click="actionOnstatuschange()"
              >
                Yes
              </button>
            </div>
          </div>
        </b-modal>
      </div>

      <footerView ref="rout_view"></footerView>
    </div>
  </div>
</template>
<script>
import profileHeader from "@/components/ProfileHeader.vue";
import footerView from "@/components/footer.vue";

import { ValidationObserver, ValidationProvider } from "vee-validate";
import VuePhoneNumberInput from "vue-phone-number-input";
import "vue-phone-number-input/dist/vue-phone-number-input.css";
import tableheader from "@/views/filters/clientFilters.vue";
import Multiselect from "vue-multiselect-inv";
import _ from "lodash";

import CKEditor from "@ckeditor/ckeditor5-vue2";
import Vue from "vue";
Vue.use(CKEditor);
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  name: "LNA",
  methods: {
    gotoDetails(item) {
      if (_.has(item, "_id")) {
        this.$router.push("/client-details/" + item["_id"]);
      }
    },
    setGloalClient(item) {
      if (this.userdata["roleId"] != 5) {
        try {
          let cl = { _id: item["_id"], name: item.busName };
          this.$store.dispatch("globalClient", cl);
          localStorage.setItem("selectedGlobalClient", JSON.stringify(cl));
          this.$refs.profile_hrader.setGlClient(cl);
          setTimeout(() => {
            this.$router.push("/dashboard");
          }, 1);
        } catch (err) {
          this.log(err);
        }
      }
    },
    onPopup3(item) {
      // item.statusId==2 , 'inactive':item.statusId== 3

      if (item["statusId"] == 2) {
        this.actionTitle = "Deactivate";
      }
      this.selectedItem = item;
      this.$refs["my-modal3"].show();
    },
    actionOnstatuschange() {
      //alert(JSON.stringify(this.selectedItem));
      let postData = {};
      postData["userId"] = this.selectedItem["_id"];
      if (this.selectedItem["statusId"] == 2) {
        postData["statusId"] = 3;
      }
      if (this.selectedItem["statusId"] == 3) {
        postData["statusId"] = 2;
      }
      this.$store
        .dispatch("updateUserStatus", postData)
        .then((res) => {
          this.$refs["my-modal3"].hide();
          this.notify({
            message: res.data.result.message,
            title: "Success",
            type: "success",
          });
          this.callfromSort = true;
          this.getList();
        })
        .catch((err) => {
          // this.addClientModel =false;
          this.notify({ message: err, title: "Error", type: "error" });
        });
    },
    hideMe() {
      this.$refs["my-modal3"].hide();
    },
    changedGlobalclient(cl) {
      this.selectedGlobalClient = cl;
      this.filterStatus = false;
      this.filterStatus = true;
      this.getInvitations = false;

      //this.$refs.rout_view.serchTitle(cl);
    },
    showModal() {
      this.$refs["my-modal"].show();
      this.new_client = {
        pricingModel: "",
        busContact: {
          fName: "",
          lName: "",
          phone: "",
          phoneCode: "+1",
          email: "",
        },
        busName: "",
      };
    },
    showModal2(item) {
      this.$refs["my-modal2"].show();
      this.selectedItem = item;
      this.set_price = { filingCharges: 0, clientId: "" };

      if (
        this.checkProperty(item, "settings", "filingCharges") != "--" &&
        this.checkProperty(item, "settings", "filingCharges") > 0
      ) {
        this.set_price.filingCharges = item["settings"]["filingCharges"];
      } else if (_.has(item, "defaultFilingCharges")) {
        this.set_price.filingCharges = item.defaultFilingCharges;
      }
      this.set_price.clientId = item["_id"];
    },

    setFilingCharges() {
      if (this.set_price.filingCharges && this.set_price.filingCharges >= 0) {
        this.$store
          .dispatch("setFilingCharges", this.set_price)
          .then((res) => {
            this.$refs["my-modal2"].hide();
            this.notify({
              message: res.data.result.message,
              title: "Success",
              type: "success",
            });
            this.callfromSort = true;
            this.getList();
          })
          .catch((err) => {
            // this.addClientModel =false;
            this.notify({ message: err, title: "Error", type: "error" });
          });
      }
    },
    hideModal() {
      this.$refs["my-modal"].hide();
    },
    hideModal2() {
      this.$refs["my-modal2"].hide();
    },
    serchTitle() {
      this.page = 1;
      this.callfromSort = true;
      this.getList();
    },
    getList() {
      //alert(JSON.stringify(this.filterData))
      this.totalPages = 0;
      let postData = {
        filters: {
          title: this.serch_title,
          busTypeIds: this.filterData.busTypeIds,
          statusIds: this.filterData.statusIds,
          sellingPlatformIds: this.filterData.sellingPlatformIds,
          nexusTypeIds: this.filterData.nexusTypeIds,
          llcTypeIds: this.filterData.llcTypeIds,
          createdOnRange: this.filterData.createdOnRange,
          stateIds: this.filterData.stateIds,
          clientIds: this.filterData.clientIds,
        },
        sorting: this.sortKey, //{"path": "name","order": -1	},
        page: this.page,
        perpage: this.perPage,
      };

      if (this.getInvitations) {
        postData["getInvitations"] = true;
      }
      if (!this.callfromSort) this.list = [];
      this.callfromSort = false;
      this.$store
        .dispatch("getClientList", postData)
        .then((response) => {
          this.list = response.list;
          this.totalPages = response.totalCount; //Math.ceil(response.totalCount / this.perPage);
          this.list.forEach((item) => {
            if (item.stateReg && item.stateReg.length > 0) {
              item.operatingStates = [];
              let arr = [];
              item.stateReg.forEach((item2) => {
                arr = item2.name.split(" ");
                if (arr.length > 1) {
                  item2.shortName = arr[0]
                    .charAt(0)
                    .concat(arr[1].charAt(0))
                    .toUpperCase();
                } else if (arr.length == 1) {
                  item2.shortName = arr[0].slice(0, 2).toUpperCase();
                }
                if (item2.shortName && item2.operating) {
                  item.operatingStates.push(item2);
                }
              });
            }
          });
        })
        .catch((err) => {
          this.log(err);
        });
    },
    toggleModal() {
      // We pass the ID of the button that we want to return focus to
      // when the modal has hidden
      this.$refs["my-modal"].toggle("#toggle-btn");
    },
    clientAdd() {
      this.$refs.sendInvitationForm.reset();
      this.addClientModel = false;
      this.openFilter = false;
      (this.new_client = {
        pricingModel: "",
        busContact: {
          fName: "",
          lName: "",
          phone: "",
          phoneCode: "+1",
          email: "",
        },
        busName: "",
      }),
        (this.formSubmited = false);
      this.addClientModel = true;
    },
    async sendInvitation() {
      //this.$validator.reset();
      //console.log( this.$refs.closeAddClientModel); // this.$refs.closeAddClientModel.$el.click();
      //this.$refs['addClient'].hide();

      this.disable_account_selection = false;
      const valid = await this.$refs.sendInvitationForm.validate();
      if (valid) {
        this.$store
          .dispatch("sendInvitation", this.new_client)
          .then((res) => {
            this.hideModal();
            this.notify({
              message: res.data.result.message,
              title: "Success",
              type: "success",
            });

            //profile_hrader getNotifyList
            try {
              this.$refs.profile_hrader.getNotifyList();
            } catch (err) {
              this.log(err);
            }
          })
          .catch((err) => {
            // this.addClientModel =false;
            this.notify({ message: err, title: "Error", type: "error" });
          });
      }
    },
    updatePhoneNumber(item) {
      if (item.isValid) {
        this.new_client.busContact.phoneCode = item.countryCallingCode;
        this.new_client.busContact.phone = item.nationalNumber;
      }
    },
    applyFilters(filterData) {
      //alert(JSON.stringify(filterData));
      this.filterData.busTypeIds = filterData.busTypeIds;
      this.filterData.statusIds = filterData.statusIds;
      this.filterData.sellingPlatformIds = filterData.sellingPlatformIds;
      this.filterData.nexusTypeIds = filterData.nexusTypeIds;
      this.filterData.llcTypeIds = filterData.llcTypeIds;
      this.filterData.createdOnRange = filterData.createdOnRange;
      this.filterData.stateIds = filterData.stateIds;
      this.filterData.clientIds = filterData.clientIds;

      this.getList();
    },
    clearFilters() {
      this.serch_title = "";
      this.filterData = {
        stateIds: [],
        busTypeIds: [],
        clientIds: [],
        statusIds: [],
        sellingPlatformIds: [],
        nexusTypeIds: [],
        llcTypeIds: [],
        createdOnRange: [],
      };

      if (
        this.selectedGlobalClient != null &&
        _.has(this.selectedGlobalClient, "_id")
      ) {
        this.filterData.clientIds.push(this.selectedGlobalClient["_id"]);
      }
      this.callfromSort = true;
      this.getList();
    },
    changePerPage() {
      this.page = 1;
      this.getList();
    },
    sortMe(sort_key = "") {
      this.sortKey = { path: "createdOn", order: 1 };
      if (sort_key != "") {
        this.sortKeys[sort_key] = this.sortKeys[sort_key] == 1 ? -1 : 1;
        this.sortKey = {};

        this.sortKey["path"] = sort_key;
        this.sortKey["order"] = this.sortKeys[sort_key] == 1 ? -1 : 1;
        this.callfromSort = true;
        this.getList();
      }
    },
  },
  data() {
    return {
      editor: ClassicEditor,
      editorConfig: {
        // The configuration of the editor.
        toolbar: [
          "bold",
          "italic",
          "|",
          "undo",
          "redo",
          "indent",
          "Outdent",
          "NumberedList",
          "BulletedList",
        ],
        //removeButtons:["Image"]
      },

      callfromSort: false,
      actionTitle: "Activate",
      isselectedGlobalCLient: false,
      filterStatus: true,
      showFilter: true,
      selectedGlobalClient: null,
      getInvitations: false,
      userdata: null,
      loaded: false,
      page: 1,
      perPage: 25,
      totalPages: 1,
      sortKey: {},
      filterData: {
        stateIds: [],
        clientIds: [],
        busTypeIds: [],
        statusIds: [],
        sellingPlatformIds: [],
        nexusTypeIds: [],
        llcTypeIds: [],
        createdOnRange: [],
      },
      perPeges: [10, 25, 50, 75, 100],
      show: false,
      serch_title: "",
      modalShow: false,
      addClientModel: false,
      list: [],
      sortKeys: {},

      errormessage: "",
      new_client: {
        pricingModel: "",
        busContact: {
          fName: "",
          lName: "",
          phone: "",
          phoneCode: "+1",
          email: "",
        },
        busName: "",
      },
      formSubmited: false,
      selectedItem: null,
    };
  },

  mounted() {
    this.userdata = this.$store.getters["auth/getuser"];
    this.loaded = true;
    this.sortKey = { path: "createdOn", order: -1 };
    this.sortKeys = {
      busName: 1,
      createdOn: -1,
    };
    this.selectedGlobalClient = this.$store.getters["getGlobalClient"];
    this.changedGlobalclient(this.selectedGlobalClient);
    if (
      this.selectedGlobalClient != null &&
      _.has(this.selectedGlobalClient, "_id")
    ) {
      this.filterData.clientIds.push(this.selectedGlobalClient["_id"]);
    }
    this.getList();
  },
  components: {
    profileHeader,
    footerView,

    ValidationObserver,
    ValidationProvider,
    VuePhoneNumberInput,
    tableheader,
    Multiselect,
  },
  watch: {
    selectedGlobalClient: function(val) {
      this.isselectedGlobalCLient = false;
      if (val != null && _.has(val, "_id") && val["_id"]) {
        this.isselectedGlobalCLient = true;
      }
    },
    page: function(val) {
      this.log(val);
      this.callfromSort = true;
      this.getList();
    },
  },
  beforeCreate() {
    this.userdata = this.$store.getters["auth/getuser"];
  },

  computed: {
    getProfile() {
      if (_.has(this.list, "logo")) {
        return this.list["logo"];
      } else {
        return "";
      }
    },
  },
};
</script>
